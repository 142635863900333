
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
import { Vue } from "vue-class-component";

export default class TopUpAndCashback extends Vue {
  // Tabs
  tabs = [
    { name: "top-up-fee", title: "Biaya Top Up", permission: FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable.isEnabled() },
    { name: "cashback", title: "Cashback", permission: FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable.isEnabled() },
  ];

  get filteredTabs() {
    return this.tabs.filter((tab: any) => {
      return tab.permission;
    });
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value,
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
